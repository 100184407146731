import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../material.module';
import {StorageDialogComponent, StoreComponent} from '../store/store.component';
import {StorageFormComponent} from './storage-form/storage-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';



@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        StoreComponent,
        StorageDialogComponent,
        StorageFormComponent
    ],
    providers: []
})
export class StoreModule {
}
