<div class="row">
    <h2>Admin page</h2>
</div>

<mat-tab-group>

    <mat-tab>
        <ng-template mat-tab-label>
            <span>Activities blueprints</span>
        </ng-template>

        <app-blueprints></app-blueprints>

    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span>Margin defaults</span>
        </ng-template>

        <app-margins></app-margins>

    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span>Reporting</span>
        </ng-template>

        <app-reporting></app-reporting>

    </mat-tab>

</mat-tab-group>
