<div class="row-centered">
    <form (ngSubmit)="submit()" [formGroup]="templateForm" class="margin-form-container">

        <div class="mb-3 mt-2 ml-2 row" formArrayName="margins">

            <mat-form-field class="mat-form-field-lg mr-1" appearance="outline">
                <mat-label>General Expenses</mat-label>
                <input type="number" required matInput formControlName="generalExpenses">
            </mat-form-field>
            <mat-form-field class="mat-form-field-lg mr-1" appearance="outline">
                <mat-label>Capital improvement taxes</mat-label>
                <input type="number" required matInput formControlName="ciTaxesPercentage">
                <mat-hint>Percentage of production cost</mat-hint>
            </mat-form-field>
            <mat-form-field class="mat-form-field-lg mr-1" appearance="outline">
                <mat-label>Profit</mat-label>
                <input type="number" required matInput formControlName="profit">
            </mat-form-field>

            <div class="give-margin-cell-reversed row">
                <button color="primary" mat-raised-button [disabled]="!templateForm.valid" i18n mat-button
                        type="submit">Submit changes
                </button>
            </div>
        </div>
    </form>
</div>