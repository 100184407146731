import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {DataService} from './services/data.service';
import {AuthService} from './services/auth.service';
import {ConfigService} from './services/config.service';
import {AppStateService} from './services/app-state.service';
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './home/home.component';
import {NoContentComponent} from './no-content/no-content.component';
import {MaterialModule} from './material.module';
import {HttpClientModule} from '@angular/common/http';
import {AngularFireModule} from '@angular/fire';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import {PrivateModule} from './private';
import {environment} from '../environments/environment';
import {GoogleApiModule, NG_GAPI_CONFIG, NgGapiClientConfig} from 'ng-gapi';
import {MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

// import and SET 'it' locale for date pipes
import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {SuperadminComponent} from './superadmin/superadmin.component';
import { AdminComponent } from './admin/admin.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BlueprintsComponent } from './admin/blueprints/blueprints.component';
import { ReportingComponent } from './admin/reporting/reporting.component';
import {SharedModule} from './shared/shared.module';
import { MarginsComponent } from './admin/margins/margins.component';



registerLocaleData(localeIt, 'it');

const gapiClientConfig: NgGapiClientConfig = {
    client_id: environment.clientId,
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/people/v1/rest'],
    scope: [
        'https://www.googleapis.com/auth/drive',
        'https://www.googleapis.com/auth/documents',
        'https://www.googleapis.com/auth/spreadsheets',
        'https://www.googleapis.com/auth/admin.directory.user'
    ].join(' ')
};

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        NoContentComponent,
        SuperadminComponent,
        AdminComponent,
        BlueprintsComponent,
        ReportingComponent,
        MarginsComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatMomentDateModule,
    MaterialModule,
    HttpClientModule,
    PrivateModule,
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule, // <-- Add it after firebase app initialization
    AngularFirestoreModule,
    AngularFireStorageModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    }),
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
    providers: [
        AuthService,
        ConfigService,
        DataService,
        AppStateService,
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        {provide: MAT_DATE_LOCALE, useValue: 'en-US'},
        {provide: LOCALE_ID, useValue: 'it-IT'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        dataService: DataService) { // this is handy for pre-loading this service
    }
}
