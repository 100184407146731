import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {takeUntil} from 'rxjs/operators';
import {ConfirmDialogComponent} from '../../private/private.component';
import {MatDialog} from '@angular/material/dialog';
import {ActivitiesTemplate} from '../../budget/interfaces/templates';



@Component({
    selector: 'app-blueprints',
    templateUrl: './blueprints.component.html',
    styleUrls: ['./blueprints.component.scss']
})
export class BlueprintsComponent implements OnInit, OnDestroy {

    latestActTemplate$: Observable<ActivitiesTemplate>;
    private unsubscribe$ = new Subject();
    public templateForm: FormGroup;

    public categories = ['AMM', 'AMS', 'AVT', 'OFI', 'OFE', 'TRI', 'UFT', 'AIM', 'TRE', 'PRI', 'ING', 'IUS', 'SPE', 'CDT', 'INS', 'CMT']
        .sort();

    constructor(private dataService: DataService,
                public dialog: MatDialog,
                private fb: FormBuilder) {
    }

    get activitiesFormArray(): FormArray {
        return (this.templateForm.get('activities') as FormArray);
    }

    ngOnInit(): void {
        this.templateForm = this.fb.group({
            id: null,
            activities: this.fb.array([]),
            latest: true,
            validFrom: new Date(),
            validTo: null
        });

        this.latestActTemplate$ = this.dataService.getLatestActivityBluePrints();

        this.latestActTemplate$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(template => {
                this.activitiesFormArray.clear();
                if (template) {
                    this.templateForm.patchValue(template);
                    for (const act of template.activities) {
                        this.activitiesFormArray.push(this.fb.group(act));
                    }
                }
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    submit() {
        const dialogMessage = {confirmMessage: `Are you sure you want to save the new activities blueprints?`};
        const dialogData = {data: dialogMessage, height: '250px', width: '600px', disableClose: true};
        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogData);

        dialogRef.afterClosed().subscribe(choice => {
            if (choice.confirm) {
                const newTemplate = this.templateForm.value;
                console.log('saving...', newTemplate);
                return this.dataService.addLatestActivityBluePrints(newTemplate);
            }
        });
    }

}
