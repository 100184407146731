<div class="row-centered">
    <form (ngSubmit)="submit()" [formGroup]="templateForm" class="margin-form-container">

        <div formArrayName="activities">
            <div class="mb-3 row"
                 *ngFor="let act of activitiesFormArray.controls; let i = index"
                 [formGroupName]="i">
                <mat-form-field class="mat-form-field-lg mr-1" appearance="outline">
                    <mat-label>Activity</mat-label>
                    <input type="text" required matInput formControlName="activity">
                    <mat-hint>
                        name of the activity
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="mr-1">
                    <mat-select formControlName="category" placeholder="Category">
                        <mat-option *ngFor="let c of categories" [value]="c">
                            {{ c }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="mr-1">
                    <mat-label>Cost</mat-label>
                    <input type="text" required matInput formControlName="cost">
                    <mat-hint>
                        €
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="mat-form-field-lg">
                    <mat-label>Name</mat-label>
                    <input type="text" required matInput formControlName="name">
                    <mat-hint>
                        name of the activity
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>

        <div class="give-margin-cell-reversed row">
            <button color="primary" i18n mat-raised-button [disabled]="!templateForm.valid" i18n mat-button type="submit">Submit changes</button>
        </div>

    </form>
</div>