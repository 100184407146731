<div class="example-container mat-elevation-z8 table-container">
    <mat-progress-bar *ngIf="tableIsLoading$ | async" mode="indeterminate" style="width:100%"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort matSortActive="creation" matSortDirection="desc" matSortDisableClear>

        <!-- Status -->
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Status</mat-header-cell>

            <mat-cell *matCellDef="let row" [ngSwitch]="row.status">
                <mat-icon *ngSwitchCase="'new'" [title]="row.status">whatshot</mat-icon>
                <mat-icon *ngSwitchCase="'assigned'" [title]="row.status">person</mat-icon>
                <mat-icon *ngSwitchCase="'edit'" [title]="row.status">star_half</mat-icon>
                <mat-icon *ngSwitchCase="'ready'" [title]="row.status">star</mat-icon>
                <mat-icon *ngSwitchCase="'approved'" [title]="row.status">check</mat-icon>
                <mat-icon *ngSwitchCase="'closed'" [title]="row.status">loop</mat-icon>
                <mat-icon *ngSwitchCase="'archived'" [title]="row.status">archived</mat-icon>
                <mat-icon *ngSwitchDefault [title]="row.status">warning</mat-icon>
            </mat-cell>
        </ng-container>

        <!-- ProjectNr -->
        <ng-container matColumnDef="projectNr">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Project Nr.</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row | projectNr }}</mat-cell>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="budgetNr">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Budget Nr.</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.budgetNr ? row.budgetNr : row.budgetNumber }}
                &nbsp;<span style="font-size: 70%">({{row.version}})</span></mat-cell>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="manager">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Project Mgr.</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.project?.projectManager?.displayName }}</mat-cell>
        </ng-container>

        <!-- PW Name Column -->
        <ng-container matColumnDef="pwName">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Opportunity Name</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.prosperworks ? row.prosperworks.name : row.description }}</mat-cell>
        </ng-container>

        <!-- PW Name Column -->
        <ng-container matColumnDef="pwCompanyName">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Company Name</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.companyName ? row.companyName : row.prosperworks?.company_name }}
            </mat-cell>
        </ng-container>

        <!-- Author Column -->
        <ng-container matColumnDef="author">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Author</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.author ? row.author.displayName : '' }}</mat-cell>
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Category</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.category }}</mat-cell>
        </ng-container>

        <!-- Creation Column -->
        <ng-container matColumnDef="creation">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Creation</mat-header-cell>
            <mat-cell *matCellDef="let row" [style.color]="row.color"> {{ row.creation | timestamp | date }}</mat-cell>
        </ng-container>

        <!-- Delivery Column -->
        <ng-container matColumnDef="deliveryThr">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Delivery Date</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.deliveryThr | timestamp | date }}</mat-cell>
        </ng-container>

        <!-- Link to Balances -->
        <ng-container matColumnDef="balances">
            <mat-header-cell *matHeaderCellDef i18n></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <a [routerLink]="['/balances/summary/',row.id]" mat-icon-button>
                    <mat-icon>euro_symbol</mat-icon>
                </a>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row (click)="goToBudget(row)" *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>

    <mat-paginator [length]="totalElements" [pageSizeOptions]="[5, 10, 25, 100, 500]" pageSize="100">
    </mat-paginator>
</div>